import React, {useEffect, useState } from 'react';

function ContactForm(props) {
    const {formName, subject, onClose, title, description} = props;

    const [formData, setFormData] = useState({
        name: formName,
        subject: subject,
        "first-name": '',
        "last-name": '',
        email: "",
        message: ""
    })

    const handleChange = e => {
        const { name, value } = e.target
        setFormData({
            ...formData, 
            [name]: value
        })
    }

    const [errors, setErrors] = useState({})
    const validate = (formData) => {
        let formErrors = {}
        if(!formData["first-name"]){
            formErrors["first-name"] = "Förnamn obligatoriskt"
        }
        if(!formData["last-name"]){
            formErrors["last-name"] = "Förnamn obligatoriskt"
        }
        if(!formData.email){
            formErrors.email = "Email required"
        } 
        return formErrors
    }

    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleSubmit = e => {
        e.preventDefault()
        setErrors(validate(formData))
        setIsSubmitted(true)

        if(Object.keys(errors).length !== 0) {
          console.log('ERRORS found', Object.keys(errors).length);
          return;
        }
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": formName, ...formData })
        })
        .then(() => {
          console.log('form submitted');
          setIsSubmitted(false)
          setFormData({"first-name": "", "last-name": "", email: "", message: ""})
          onClose()
        })
        .catch(error => alert(error))
    }

    const encode = (data) => {
      // console.log('ENCODING', data);
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    return (
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
          <div className="relative max-w-xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {title}
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                {description}
              </p>
            </div>
            <div className="mt-12">
              <form
                name={formName}
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value={formName} />
                <input type="hidden" name="subject" value={subject} />
                <input type="hidden" name="name" value={formName} />
                <p className="hidden">
                  <label>Don’t fill this out if you’re human!: <input name="bot-field" /></label>
                </p>
                <div className="sm:col-span-2">
                  <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700">Förnamn</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      id="first_name"
                      name="first-name"
                      onChange={handleChange}
                      value={formData["first-name"]}
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 text-gray-900"
                    />
                    {errors["first-name"] && <p>{errors["first-name"]}</p>}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700">Efternamn</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      id="last_name"
                      name="last-name"
                      onChange={handleChange}
                      value={formData["last-name"]}
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 text-gray-900"
                    />
                    {errors["last-name"] && <p>{errors["last-name"]}</p>}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">E-post</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      type="email"
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 text-gray-900"
                    />
                    {errors.email && <p>{errors.email}</p>}
                  </div>
                </div>
                {formName === "contactus-form" ? 
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-medium leading-5 text-gray-700">Meddelande</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      onChange={handleChange}
                      value={formData.message}
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 text-gray-900"
                    />
                    {errors.message && <p>{errors.message}</p>}
                  </div>
                </div>
                : null}
                <div className="sm:col-span-2">
                </div>
                <div className="sm:col-span-2">
                  {/* <toast v-if="toastVisible" message="Tack för din intresseanmälan!" /> */}
                  <span className="w-full inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150"
                    >
                      Skicka
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
    );
}

export default ContactForm;